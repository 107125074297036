import cx from 'classnames'

import styles from './Page.module.scss'

export const Page: React.FC<React.PropsWithChildren<{ className?: string; dataCy?: string }>> = ({
  children,
  className: additionalClasses,
  dataCy,
}) => {
  return (
    <div data-cy={dataCy} className={cx(styles.Page, additionalClasses)}>
      <main className={styles.Container}>{children}</main>
    </div>
  )
}
