import { GameId } from '@common'
import Head from 'next/head'
import { useTranslations } from 'next-intl'

import { PageMetaTags } from '@/components/MetaTags'
import { Page } from '@/components/Page'
import { OurGames } from '@/features/storefronts/landing/OurGames'
import { StoreCards } from '@/features/storefronts/landing/StoreCard'
import { games as allGames } from '@/fixtures/games'
import { sortByPriority } from '@/utils/sortGames'

import { wrapGetServerSideProps, setPublicPageCacheControl } from '../ssr/wrapper'

import styles from './landingpage.module.scss'

type Props = {
  games: GameId[]
}
export default function Home({ games }: Props) {
  const t = useTranslations()
  return (
    <Page>
      <PageMetaTags title={t('sitename')} description={t('home_description')} />
      <Head>
        <title>{t('sitename')}</title>
      </Head>
      <div className={styles.Sections}>
        <StoreCards games={games} />
        <OurGames games={games} />
      </div>
    </Page>
  )
}

export const getServerSideProps = wrapGetServerSideProps(async (store, context) => {
  setPublicPageCacheControl(context, 30, 60)
  const games = sortByPriority(allGames).map((game) => game.slug)

  return {
    props: {
      games,
    },
  }
}, 'storeInitSSR')
