import { GameId } from '@common'
import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { games as allGames, Game } from '@/fixtures/games'

import { config } from '../../../../config'
import { useAppSelector } from '../../../utils/useAppSelector'

import BrawlStarsCard from 'assets/images/landing-cards/store-card-bs-1.png'
import ClashOfClansCard from 'assets/images/landing-cards/store-card-coc-1.png'
import ClashRoyaleCard from 'assets/images/landing-cards/store-card-cr-1.png'
import HayDayCard from 'assets/images/landing-cards/store-card-hd.png'
import SquadBustersCard from 'assets/images/landing-cards/store-card-sb-1.png'

import styles from './StoreCard.module.scss'

const cardImages = {
  clashofclans: ClashOfClansCard,
  brawlstars: BrawlStarsCard,
  clashroyale: ClashRoyaleCard,
  hayday: HayDayCard,
  squadbusters: SquadBustersCard,
}

const StoreCard = ({ game }: { game: Game }) => {
  const t = useTranslations()
  const viewerCountry = useAppSelector((state) => state.config.viewerCountry)
  const comingSoon =
    viewerCountry && config.SQUAD_DISABLED_COUNTRIES.includes(viewerCountry) && game.slug === 'squadbusters'

  return (
    <a href={`/${game.slug}`} className={styles.Card} data-cy={'game-store-' + game.slug}>
      {comingSoon ? (
        <div className={styles.Ribbon} dir="ltr">
          <span>{t('lan_coming_soon')}</span>
        </div>
      ) : null}
      <div className={cx(styles.Image, styles[game.slug])}>
        <Image className={styles.CardBg} src={cardImages[game.slug]} alt="" quality={100} width={360} />
      </div>
      <div className={styles.CardFooter}>
        <div className={styles.FooterContent}>
          <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.1036 4.85355C13.2988 4.65829 13.2988 4.34171 13.1036 4.14645L9.92157 0.964466C9.72631 0.769204 9.40973 0.769204 9.21447 0.964466C9.0192 1.15973 9.0192 1.47631 9.21447 1.67157L12.0429 4.5L9.21447 7.32843C9.0192 7.52369 9.0192 7.84027 9.21447 8.03553C9.40973 8.2308 9.72631 8.2308 9.92157 8.03553L13.1036 4.85355ZM0.75 5H12.75V4H0.75V5Z"
              fill="black"
            />
          </svg>
          <span>{t(`${game.shortSlug}_home_gamename_title`)}</span>
        </div>
      </div>
    </a>
  )
}

type Props = {
  games: GameId[]
}
export function StoreCards({ games }: Props) {
  const enabledGames = allGames.filter((game) => games.includes(game.slug))
  return (
    <div className={styles.GameCards}>
      {enabledGames.map((game) => (
        <StoreCard key={game.slug} game={game} />
      ))}
    </div>
  )
}
