import { GameId } from '@common'
import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { useGameImages } from '@/components/games/images'
import { useLocale } from '@/features/locale/useLocale'
import { localizedAppStoreButtons } from '@/fixtures/localizedAppStoreButtons'

import styles from './OurGames.module.scss'

const GameIcon = ({ gameId }: { gameId: GameId }) => {
  const { gameImage } = useGameImages(gameId)
  return <Image alt="" src={gameImage('appIcon')} width={56} quality="100" />
}

type Props = {
  games: GameId[]
}
export function OurGames({ games }: Props) {
  const locale = useLocale()
  const t = useTranslations()
  return (
    <div className={styles.Container}>
      <p className={cx(styles.Title, styles[locale])}>{t('gen_download_games')}</p>
      <div className={styles.GameIcons}>
        {games.map((game) => (
          <GameIcon key={game} gameId={game} />
        ))}
      </div>
      <div className={styles.AppStoreButtons}>
        <a href={t('gen_apap')} target="_blank" rel="noreferrer">
          <Image src={localizedAppStoreButtons[locale].appStore} height={42} alt="" />
        </a>
        <a href={t('gen_apgl')} target="_blank" rel="noreferrer">
          <Image src={localizedAppStoreButtons[locale].playBadge} width={141} height={42} alt="" />
        </a>
      </div>
    </div>
  )
}
